import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const FamiljenSandwall = ({ data }) => (
  <Layout
    pageTitle='Kundcase - Familjen Sandwall'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='50% 80%'
  >
    <oma-grid-row>
      <SectionWithColumnLayout title='Kundcase'>
        <h2 className='section__sub-heading'>FAMILJEN SANDWALL</h2>
        <p className='section__text'>
          Familjen Sandwall är en skönhetssalong som riktar sig lika mycket till
          män som till kvinnor och har ett brett utbud av tjänster såsom
          frisörer, barberare, nagel-, frans- och brynvård. Dessutom jobbar man
          med peruker och Liza är utbildad peruktekniker. Liza Hermansson driver
          salongen sedan 2012 tillsammans med Janne Larsson, Familjen Sandwall
          mitt i Borås på Sandwalls plats.
        </p>
        <p className='section__text'>
          Sedan starten 2012 har företaget ständigt vuxit både
          omsättningsmässigt och på personalsidan. Idag består Familjen Sandwall
          av totalt 16 personer, 8 är anställda inom Familjen Sandwall och
          övriga 8 hyr stolar av företaget. En del som är lite unikt är att
          företaget och då framförallt Liza jobbar mycket med utbildning och
          samarbetar med Almåsskolan i Borås. Ca 3 dagar/vecka ägnar Liza sin
          tid och kompetens till att lära upp och inspirerar nya talanger.
          <i>
            Detta är något jag brinner för, jag lär mig och inspireras lika
            mycket av dom som dom av oss,
          </i>{' '}
          säger Liza.
        </p>
        <p className='section__text'>
          Liza kom i kontakt med Ertan genom BNI för drygt 11 år sedan, då
          jobbade hon på en annan salong i Borås som på Lizas rekommendation då
          valde Ertan. När det sedan var dax att starta Familjen Sandwall var
          det en självklarhet att välja Ertan för redovisning- och
          bokföringsuppdraget. Idag sköter Liza själv det löpande bokföringen
          och då genom Fortnox.{' '}
          <i>
            En gång i månaden lämnar jag in våra papper som Ertan sedan tar över
            och gör ”sina” bitar. Ertan tar bl a ansvar för moms, skatt, löner
            och bokslut. Dom är också alltid ett uppskattat bollplank när det
            behövs. Vid något tillfälle har Ertan även bistått vid indrivning av
            en obetalda fakturor. Ertan är också snabba på att återkoppla om det
            är några regeländringar eller annat jag behöver veta så jag känner
            med helt trygg i händerna på dom,
          </i>{' '}
          säger avslutar Liza.
        </p>
        <p className='section__text'>
          Framtiden för Familjen Sandwall ser ljus ut och just nu väntar man på
          att få ta över ytterligare en lägenhet i huset på Sandwalls plats och
          på så vis kunna expandera. När detta är gjort kommer det utöver dagens
          utbud av tjänster även finnas hudterapeuter och hudläkare på plats.
        </p>
      </SectionWithColumnLayout>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "familjen-sandwall.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 853
          height: 700
          quality: 80
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`

export default FamiljenSandwall
